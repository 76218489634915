import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Loader from './components/Loader'
import PrivateRoute from './containers/PrivateRoute'
import MobileMiddleRoute from './containers/MobileMiddleRoute'

const AuthBase = lazy(() => import('./containers/AuthBase'))
const BankAccountBase = lazy(() => import('./containers/BankAccountBase'))
const CreditCardBase = lazy(() => import('./containers/CreditCardBase'))
const EWalletBase = lazy(() => import('./containers/EWalletBase'))
const DesignGuideBase = lazy(() => import('./containers/DesignGuideBase'))
const EditProfileBase = lazy(() => import('./containers/EditProfileBase'))
// const HomeBase = lazy(() => import('./containers/HomeBase'))
const MessagesBase = lazy(() => import('./containers/MessagesBase'))
const OnboardingBase = lazy(() => import('./containers/OnboardingBase'))
const PaymentsBase = lazy(() => import('./containers/PaymentsBase'))
const PortfolioBase = lazy(() => import('./containers/PortfolioBase'))
const ProfileBase = lazy(() => import('./containers/ProfileBase'))
const ProjectBase = lazy(() => import('./containers/ProjectBase'))
const ProjectsBoardBase = lazy(() => import('./containers/ProjectsBoardBase'))
const ServiceBase = lazy(() => import('./containers/ServiceBase'))
const ServicePublicBase = lazy(() => import('./containers/ServicePublicBase'))
const StaticPageBase = lazy(() => import('./containers/StaticPageBase'))
const ArtistsBoardBase = lazy(() => import('./containers/ArtistsBoardBase'))
const ServicesBoardBase = lazy(() => import('./containers/ServicesBoardBase'))
const GeneralSearchBase = lazy(() => import('./containers/GeneralSearchBoardBase'))
const HowItWorksBase = lazy(() => import('./containers/HowItWorksBase'))
const PortfolioPublicBase = lazy(() => import('./containers/PortfolioPublicBase'))
const MobileSearchBase = lazy(() => import('./containers/MobileSearchBase'))
const ProjectPublicBase = lazy(() => import('./containers/ProjectPublicBase'))
const PaymentsMobileRedirectBase = lazy(() => import('./containers/PaymentsBase/MobileRedirect'))
const DirectHireBase = lazy(() => import('./containers/DirectHireBase'))
const RequestQoutationBase = lazy(() => import('./containers/DirectHireBase/RequestQoutationForm'))
const HomePageV2 = lazy(() => import('./containers/HomeBaseV2'))
const RefferralBase = lazy(() => import('./containers/ReferralBase'))

// NFT Containers
const NFTHome = lazy(() => import('./nft/NFTHomeBaseComponent'))

/**
  * Render function that wraps standard routes
  * with suspense
  */
const wrapSuspense = (Component) => (
  <Suspense fallback={<Loader />}>
    <Component />
  </Suspense>
)

export default () => (
  <BrowserRouter>
    <Switch>
      <Route exact path='/' render={() => wrapSuspense(HomePageV2)} />
      <Route path='/auth' render={() => wrapSuspense(AuthBase)} />
      <Route exact path='/design' render={() => wrapSuspense(DesignGuideBase)} />
      <Route path='/how-it-works' render={() => wrapSuspense(HowItWorksBase)} />
      <Route path='/portfolio-details/:userCode?/:portfolioIndex?' render={() => wrapSuspense(PortfolioPublicBase)} />
      <Route path='/service-details/:serviceId' render={() => wrapSuspense(ServicePublicBase)} />
      <Route path='/project-details/:id' render={() => wrapSuspense(ProjectPublicBase)} />
      {/* exempted route (dynamic route) */}
      <Route
        path='/pages/:slug'
        render={(props) =>
          <Suspense fallback={<Loader />}>
            <StaticPageBase key={props.match.params.slug} />
          </Suspense>}
      />
      <Route path='/all' render={() => wrapSuspense(GeneralSearchBase)} />

      {/* public NFT PAGES */}
      <Route path='/nft' render={() => wrapSuspense(NFTHome)} />
      <PrivateRoute>
        {() => (
          <Switch>
            <Route path='/onboarding' render={() => wrapSuspense(OnboardingBase)} />
            <Route path='/profile/:userId' render={() => wrapSuspense(ProfileBase)} />
            <Route path='/edit-profile' render={() => wrapSuspense(EditProfileBase)} />
            <Route path='/project' render={() => wrapSuspense(ProjectBase)} />
            <Route path='/projects' render={() => wrapSuspense(ProjectsBoardBase)} />
            <Route path='/messages/:token?' render={() => wrapSuspense(MessagesBase)} />
            <Route path='/credit-card' render={() => wrapSuspense(CreditCardBase)} />
            <Route path='/e-wallet' render={() => wrapSuspense(EWalletBase)} />
            <Route path='/bank-account/:bankAccountIndex?' render={() => wrapSuspense(BankAccountBase)} />
            <Route path='/service/:serviceId?' render={() => wrapSuspense(ServiceBase)} />
            <Route path='/portfolio/:portfolioIndex?' render={() => wrapSuspense(PortfolioBase)} />
            <Route path='/payments' render={() => wrapSuspense(PaymentsBase)} />
            <Route path='/artists' render={() => wrapSuspense(ArtistsBoardBase)} />
            <Route path='/services' render={() => wrapSuspense(ServicesBoardBase)} />
            <Route path='/direct-hire/:id' render={() => wrapSuspense(DirectHireBase)} />
            <Route path='/request-qoutation/:id' render={() => wrapSuspense(RequestQoutationBase)} />
            <Route path='/referral' render={() => wrapSuspense(RefferralBase)} />

            {/* route only available when on mobile & tablets */}
            <MobileMiddleRoute>
              {() => (
                <Switch>
                  <Route path='/mobile-search' render={() => wrapSuspense(MobileSearchBase)} />
                  <Route path='/payments-mobile/redirect' render={() => wrapSuspense(PaymentsMobileRedirectBase)} />
                </Switch>
              )}
            </MobileMiddleRoute>
          </Switch>
        )}
      </PrivateRoute>
    </Switch>
  </BrowserRouter>
)
